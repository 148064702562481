@import url(https://fonts.googleapis.com/css?family=Roboto+Slab:400,700);

@import "variables";
@import "libs";
@import "buttons";

#app {
	[v-cloak] {
		display: none;
	}

	.all-catches {
		> .row:first-child {
			> .col {
				column-count: 2;

				@include media-breakpoint-down(lg) {
					column-count: 1;
				}

				.catch {
					display: inline-block;
					width: 100%;
				}
			}
		}
	}
}